#root { 
    display: flex; 
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
}
  
footer{
    margin-top:auto; 
}

.rdtCounters {
    .rdtCount {
        font-size: 0.9rem;
    }
}
.pb-400 {
    padding-bottom: 400px;
}
.mt--400 {
    margin-top: -400px;
}
.partnerimg {
    display: inline-block;
    vertical-align: middle;
    padding: 28px;
    border: 0;
    .partner_logo {
        width: auto!important;
        height: 36px;
        object-fit: contain;
        visibility: visible;
        animation-delay: 0.5s;
        animation-name: fadeInUp;
    }
}
.cursor-pointer {
    cursor: pointer;
}

.error-rt {
    border: 1px solid red;
}
.logo-link-rt {
    img {
        height: 100px;
    }
}
.z--1 {
    z-index: -1;
}

.carousel.slide {
    width: 100%;
}

.shadow-rt {
    box-shadow: 0 15px 35px rgb(50, 50, 93), 0 5px 15px rgb(0, 0, 0) !important;
}
.color-black {
    color: black;
    font-weight: 600;
}
.mt-6-rt {
    margin-top: 6rem;
}
.bg-icons-rt {
    background: #1a174d;
}
#root {
    p {
        color: black;
    }
    table {
        thead {
            th {
                color: rgb(26, 71, 144);
                padding: .5rem;
            }
        }
        tbody {
            td {
                padding: .5rem;
            }
            tr {
                td:first-child {
                    display: flex;
                }
            }
        }
    }
}

.footer-link-rt {
    color: #8898aa !important;
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
    &:hover {
        color: #8898aa !important;
    }
}

.terms-container-rt {
    ul {
        list-style: auto;
    }
}

.auto-complete-rt {
    position: relative;
    width: 1%;
    flex: 1 1 auto;
    div {
        position: absolute;
        top: 100%;
        background: white;
        z-index: 9999;
        width: 100%;
        cursor: pointer;
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
        border-radius: .3rem;
        max-height: 18rem;
        overflow-y: auto;
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                font-size: 1rem;
                padding: .4rem 1rem;
                display: flex;
                align-items: center;
                &:hover {
                    background: lightgray; 
                }
            }
        }
    }
}

@media only screen and (max-width: 767px){
    .logo-link-rt img {
        height: auto !important;
        width: 65px !important;
    }
    .navbar-main.navbar-transparent {
        padding: 0.5rem 1rem !important;
    }
    .bg-gradient-secondary  h1.mb-1.font-weight-800 {
        font-size: 1rem !important;
    }
    .bg-gradient-secondary p.mt-0.para {
        font-size: 0.80rem !important;
    }
    section.section.mt-6-rt.pt-0.searchform {
        margin-top: 5rem !important;
    }

}
@media only screen and (min-width: 768px){
section.section.mt-6-rt.pt-0.searchform {
    margin-top: 140px !important;
}
}
@media only screen and (min-width: 990px){
.bg-gradient-secondary.shadow.hourview.card {
    width: 700px;
    margin-left: 8%;
}
}

@media only screen and (max-width: 370px){
    
.mt-2.d-flex.form-group label.ml-2 {
    font-size: .70rem;
}
.bg-gradient-secondary  h1.mb-1.font-weight-800 {
    font-size: .90rem !important;
}
.card-body {
    padding: 0.8rem 1rem;
    
}
.card-body .h1 {
    font-size: .8rem !important;
}
.card-body .mt-4.form-group {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}
.p-lg-5.color-black.card-body h1{
.h1 {
    font-size: 1rem !important;
}
}
    
    }


.hourView .input-group-alternative.mr-2.input-group {
    margin-right: 0px !important;
}